import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Let's <code>crack</code> FAANG!
        </p>
        <a
          className="App-link"
          href="/"
          rel="noopener noreferrer"
        >
          NGneer.in
        </a>
      </header>
    </div>
  );
}

export default App;
